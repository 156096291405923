
const digest = 'cdfe775280c3767bbb174b708bf78323c186a5de494bb64716ee661dc1d91c11';
const css = `._badge_1nbd8_1 {
  background: var(--project-color);
  border: 1px solid var(--project-color);
  border-radius: 0.1875rem;
  color: var(--color-white);
  display: inline-flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
  width: max-content;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: background 0.2s var(--transition);
}

/* Sizes */

._xSmall_1nbd8_21 {
  border-radius: 0.125rem;
  font-size: 0.625rem;
  padding: 1px 2px 0;
  line-height: 1;

  min-height: 0.75rem;
  min-width: 0.75rem;
}

._small_1nbd8_31 {
  border-radius: 0.125rem;
  font-size: 0.75rem;
  padding: 1px 3px;
  line-height: 1;

  min-height: 1rem;
  min-width: 1rem;
}

._medium_1nbd8_41 {
  font-size: 0.875rem;
  line-height: 1.1;
  padding: 0.125rem 0.25rem;

  min-height: 1.25rem;
  min-width: 1.25rem;
}

._large_1nbd8_50 {
  font-size: 1rem;
  line-height: 1;
  padding: 0.5rem 0.5rem 0.4rem;

  min-height: 1.5rem;
  min-width: 1.5rem;
}

/* Variants */

._outline_1nbd8_61 {
  background: var(--color-white);
  color: var(--color-neutral-10);
}

/* Intents */

._none_1nbd8_68 {
  background: var(--color--neutral-30);
  color: var(--color-black);
  border: 1px solid var(--color--neutral-30);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"badge":"_badge_1nbd8_1","xSmall":"_xSmall_1nbd8_21","small":"_small_1nbd8_31","medium":"_medium_1nbd8_41","large":"_large_1nbd8_50","outline":"_outline_1nbd8_61","none":"_none_1nbd8_68"};
export { css, digest };
  