
const digest = '5f8e001b12d8b0791efa92e1d591bbc709cb5970f4420bf46794f9bf08d07a25';
const css = `._negative_1u5vo_1 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_1u5vo_1"};
export { css, digest };
  