import {
  FC,
  FocusEvent,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import * as React from "react";

import { createComponent, FeedbackIntent, styled } from "@plan/core";
import { IconCheckmarkCircleOutlined, IconErrorOutlined } from "@plan/icons";

import { useField } from "../Field";

import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import * as LabelPrimitive from "@radix-ui/react-label";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { AnimatePresence, motion } from "framer-motion";
import { kebabCase, uniqueId } from "lodash";

function mergeRefs<T = unknown>(
  ...refs: React.ForwardedRef<T>[]
): React.RefCallback<T> {
  return (node: T) => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === "function") ref(node);
        if ("current" in ref) ref.current = node;
      }
    }
  };
}

const component = createComponent();

const StyledInput = styled("input", {
  ...component,
  border: "none",
  outline: "none",
  color: "$colors$neutral40",
  lineHeight: "1",
  // Hide number select arrows on number input
  "-moz-appearance": "textfield",
  "&::-webkit-outer-spin-button": {
    display: "none",
  },
  "&::-webkit-inner-spin-button": {
    display: "none",
  },
  "&&": {
    flexGrow: 1,
  },
  "&:disabled": {
    cursor: "not-allowed",
    background: "$colors$-neutral40",
    color: "$colors$-neutral10",
  },
  "&::placeholder": {
    color: "$colors$neutral",
  },
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
  },
});

const disabledStyles = {
  cursor: "not-allowed",
  background: "$colors$-neutral40",
  color: "$colors$-neutral10",
};

const InputBox = styled("div", {
  ...component,
  $$lightBorder: "1px solid $colors$-neutral20",
  $$defaultBorder: "1px solid $colors$-neutral10",
  $$hoverBorder: "1px solid $colors$neutral40",
  $$focusBorder: "2px solid $colors$-brand10",
  $$focusHoverBorder: "2px solid $colors$brand",
  $$errorBorder: "1px solid $colors$danger",
  border: "$$defaultBorder",
  display: "flex",
  alignItems: "center",
  verticalAlign: "baseline",
  background: "$white",
  borderRadius: "$radii$default",
  width: "100%",
  height: "$sizes$xl",
  textStyle: "large",
  padding: "0 $space$3",
  overflow: "hidden",
  variants: {
    ...component.variants,
    disabled: {
      true: {
        ...disabledStyles,
        "&:hover": {
          border: "$$defaultBorder",
        },
        [`& ${StyledInput}`]: {
          ...disabledStyles,
        },
      },
    },
    invalid: {
      true: {
        border: "$$errorBorder",
        "&:hover": {
          border: "$$errorBorder",
        },
      },
    },
    align: {
      left: {
        [`& ${StyledInput}`]: {
          textAlign: "left",
        },
      },
      center: {
        [`& ${StyledInput}`]: {
          textAlign: "center",
        },
      },
      right: {
        [`& ${StyledInput}`]: {
          textAlign: "right",
        },
      },
    },
    lightBorder: {
      true: {
        border: "$$lightBorder",
        "&:hover": {
          border: "$$lightBorder",
        },
      },
    },
    combined: {
      left: {
        borderRightStyle: "none",
        borderTopRightRadius: "unset",
        borderBottomRightRadius: "unset",
        "&:hover": {
          borderRightStyle: "none",
          borderTopRightRadius: "unset",
          borderBottomRightRadius: "unset",
        },
      },
      right: {
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
        "&:hover": {
          borderTopLeftRadius: "unset",
          borderBottomLeftRadius: "unset",
        },
      },
      center: {
        borderTopLeftRadius: "unset",
        borderTopRightRadius: "unset",
        borderBottomRightRadius: "unset",
        borderBottomLeftRadius: "unset",
        borderRightStyle: "none",
        borderLeftStyle: "none",
        "&:hover": {
          borderTopLeftRadius: "unset",
          borderTopRightRadius: "unset",
          borderBottomRightRadius: "unset",
          borderBottomLeftRadius: "unset",
          borderRightStyle: "none",
          borderLeftStyle: "none",
        },
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    align: "left",
  },
  "&:hover": {
    border: "$$hoverBorder",
  },
  "&:focus, &:focus-within": {
    border: "$$focusBorder",
    "&:hover": {
      border: "$$focusHoverBorder",
    },
  },
});

const StyledSpan = styled("span", {
  position: "relative",
  color: "$colors$neutral10",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  variants: {
    type: {
      prefix: {
        paddingRight: "$space$1",
      },
      suffix: {
        paddingLeft: "$space$1",
      },
    },
  },
});

export const StyledLabel = styled(LabelPrimitive.Root, {
  ...component,
  textStyle: "large",
  color: "$colors$neutral10",
  marginBottom: "$space$2",
  whiteSpace: "nowrap",
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "hug-contents",
  },
});

export const Label = ({ showLabel = true, ...children }) => {
  if (!showLabel)
    return (
      <VisuallyHidden.Root asChild>
        <StyledLabel {...children} />
      </VisuallyHidden.Root>
    );
  return <StyledLabel {...children} />;
};

// TODO: Put this in plan's @hooks once it gets merged
export const useGetMemoizedHtmlId = (label: React.ReactNode): string => {
  const id = useMemo(
    // FIXME: drop on first refactor
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => uniqueId(`${kebabCase(label.toString())}-`),
    [label]
  );
  return id;
};

export const InputWrapper = styled("div", {
  ...component,
  display: "flex",
  flexDirection: "column",
  // TODO: AutoLayout Quirks (MGX-342)
  //       1. Investigate how to avoid bespoke flex-basis
  "&&": {
    flexBasis: "auto",
  },
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
  },
});

const CheckContainer = styled(motion.div, {
  position: "absolute",
  top: 5,
  right: 5,
  width: "15px",
  height: "15px",
});

const InputFeedback: FC<{ intent?: FeedbackIntent }> = ({ intent }) => {
  return (
    <AnimatePresence>
      {intent && (
        <CheckContainer
          exit={{ opacity: 0, y: -15 }}
          transition={{ duration: 0.8 }}
        >
          {intent === "success" ? (
            <IconCheckmarkCircleOutlined label="success" color="-success10" />
          ) : (
            <IconErrorOutlined label="error" color="-danger10" />
          )}
        </CheckContainer>
      )}
    </AnimatePresence>
  );
};

/**
 * Will be removed upon removal of input `label` and `showLabel` props
 */
export type InputTransitionalProps =
  | {
      withinField?: false;
      /** @deprecated Use <FieldLabel> inside `<Field>` */
      label: React.ReactNode;
      /** @deprecated Use <FieldLabel visuallyHidden> inside `<Field>` */
      showLabel?: boolean;
      invalid?: boolean;
    }
  | { withinField: true; label?: never; showLabel?: never; invalid?: boolean };

export type InputProps = Omit<
  React.ComponentProps<typeof StyledInput>,
  "prefix"
> &
  InputTransitionalProps & {
    prefix?: React.ReactNode | JSX.Element;
    suffix?: React.ReactNode;
    align?: "left" | "center" | "right";
    disabled?: boolean;
    invalid?: boolean;
    clearableValue?: string;
    combined?: "left" | "center" | "right";
    feedback?: FeedbackIntent;
    lightBorder?: boolean;
    maskOptions?: MaskitoOptions;
  };

export type InputHandle = {
  focus: () => void;
};

export const Input = forwardRef<InputHandle, InputProps>(
  (
    {
      showLabel = true,
      label,
      id,
      prefix,
      suffix,
      disabled = false,
      invalid = false,
      align = "left",
      // For combined inputs, when 2 inputs are sharing the same border
      combined,
      withinField,
      required,
      clearableValue,
      feedback,
      lightBorder,
      maskOptions,
      onChange,
      ...props
    }: InputProps,
    ref
  ) => {
    const { getInputProps } = useField();
    const inputProps = getInputProps({ id, disabled, invalid, required });
    const inputRef = useRef<HTMLInputElement>(null);

    const memoizedHtmlId = useGetMemoizedHtmlId(label || "within-field");

    useImperativeHandle(
      ref,
      () => {
        return {
          focus() {
            inputRef.current?.focus();
          },
        };
      },
      []
    );

    const maskedInputRef = useMaskito({ options: maskOptions });
    const isMasked = maskOptions !== undefined;

    const refs = isMasked ? mergeRefs(inputRef, maskedInputRef) : inputRef;
    const type = isMasked ? "text" : props.type; // Masked inputs must be text

    const changeHandlers = isMasked
      ? {
          onChange: undefined,
          onInput: onChange,
        }
      : {
          onChange: onChange,
          onInput: undefined,
        };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (props.onFocus) props.onFocus(e);
      if (!clearableValue) return;

      if (e.target.value === clearableValue) {
        e.target.value = "";
      }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (props.onBlur) props.onBlur(e);
      if (!clearableValue) return;

      if (e.target.value === "") {
        e.target.value = clearableValue;
      }
    };

    return withinField ? (
      <InputBox
        disabled={inputProps.disabled}
        invalid={inputProps.invalid}
        align={align}
        combined={combined}
        lightBorder={lightBorder}
      >
        {prefix && <StyledSpan type="prefix">{prefix}</StyledSpan>}
        <StyledInput
          {...inputProps}
          {...props}
          invalid={inputProps.invalid ? 1 : undefined}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={refs}
          type={type}
          {...changeHandlers}
        />
        <InputFeedback intent={feedback} />
        {suffix && <StyledSpan type="suffix">{suffix}</StyledSpan>}
      </InputBox>
    ) : (
      <InputWrapper>
        <Label htmlFor={memoizedHtmlId} showLabel={showLabel}>
          {label}
        </Label>
        <InputBox
          disabled={disabled}
          invalid={invalid}
          align={align}
          combined={combined}
        >
          {prefix && <StyledSpan type="prefix">{prefix}</StyledSpan>}
          <StyledInput
            id={memoizedHtmlId}
            disabled={disabled}
            required={required}
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={refs}
            type={type}
            {...changeHandlers}
          />
          <InputFeedback intent={feedback} />
          {suffix && <StyledSpan type="suffix">{suffix}</StyledSpan>}
        </InputBox>
      </InputWrapper>
    );
  }
);
