
const digest = '35f0a39cfe8c6e1c5b556fd89de0a06dcaac5a816139a1b53852aea8cb00a292';
const css = `._input_1rnjx_1 {
  align-items: stretch;
  background: transparent;
  border: none;
  font-size: 1rem;
  height: 100%;
  line-height: 2rem;
  margin-right: auto;
  min-width: 0;
  outline: none;
  padding: 0.875rem 0.75rem 0;
  width: 100%;
}

._input_1rnjx_1::placeholder {
  color: var(--color-legacy-gray-3);
}

._input_1rnjx_1:invalid {
  box-shadow: none;
}

._input-small_1rnjx_23 {
  padding: 0;
}

._input-currency_1rnjx_28 {
  padding-right: 0.75rem;
  text-align: right;
}

._input-percent_1rnjx_34 {
  padding-left: 0.75rem;
  text-align: right;
}

._input-hours_1rnjx_40 {
  padding-left: 0.75rem;
  text-align: right;
}

._input-perHour_1rnjx_46 {
  padding-left: 0;
  text-align: right;
}

._input-time_1rnjx_52 {
  padding-right: 0.25rem;
  text-align: right;
}

._format_1rnjx_58 {
  align-items: center;
  color: var(--color-neutral-10);
  display: flex;
  flex-grow: 0;
  height: 100%;
  justify-content: center;
  line-height: 1.1;
  padding: 0.875rem 0.75rem 0;
  /* width: 100%; */
}

._format-hours_1rnjx_70,
._format-perHour_1rnjx_71 {
  display: flex;
  font-size: 0.75rem;
  justify-items: flex-end;
  padding-left: 0.25rem;
}

._format-currency_1rnjx_79 {
  align-self: flex-start;
  justify-content: flex-start;
}

._format-percent_1rnjx_85 {
  padding-left: 0;
}

._perHourSeparater_1rnjx_90 {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

._format-hours_1rnjx_70._small_1rnjx_95,
._format-perHour_1rnjx_71._small_1rnjx_95,
._format-currency_1rnjx_79._small_1rnjx_95,
._format-percent_1rnjx_85._small_1rnjx_95 {
  padding: 0 0.75rem;
}

._format-hours_1rnjx_70._small_1rnjx_95,
._format-percent_1rnjx_85._small_1rnjx_95,
._format-perHour_1rnjx_71._small_1rnjx_95 {
  padding-left: 0.25rem;
}

._isOverBudget_1rnjx_108 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"input":"_input_1rnjx_1","input-small":"_input-small_1rnjx_23 _input_1rnjx_1","inputSmall":"_input-small_1rnjx_23 _input_1rnjx_1","input-currency":"_input-currency_1rnjx_28 _input_1rnjx_1","inputCurrency":"_input-currency_1rnjx_28 _input_1rnjx_1","input-percent":"_input-percent_1rnjx_34 _input_1rnjx_1","inputPercent":"_input-percent_1rnjx_34 _input_1rnjx_1","input-hours":"_input-hours_1rnjx_40 _input_1rnjx_1","inputHours":"_input-hours_1rnjx_40 _input_1rnjx_1","input-perHour":"_input-perHour_1rnjx_46 _input_1rnjx_1","inputPerHour":"_input-perHour_1rnjx_46 _input_1rnjx_1","input-time":"_input-time_1rnjx_52 _input_1rnjx_1","inputTime":"_input-time_1rnjx_52 _input_1rnjx_1","format":"_format_1rnjx_58","format-hours":"_format-hours_1rnjx_70 _format_1rnjx_58","formatHours":"_format-hours_1rnjx_70 _format_1rnjx_58","format-perHour":"_format-perHour_1rnjx_71 _format_1rnjx_58","formatPerHour":"_format-perHour_1rnjx_71 _format_1rnjx_58","format-currency":"_format-currency_1rnjx_79 _format_1rnjx_58","formatCurrency":"_format-currency_1rnjx_79 _format_1rnjx_58","format-percent":"_format-percent_1rnjx_85 _format_1rnjx_58","formatPercent":"_format-percent_1rnjx_85 _format_1rnjx_58","perHourSeparater":"_perHourSeparater_1rnjx_90","small":"_small_1rnjx_95","isOverBudget":"_isOverBudget_1rnjx_108"};
export { css, digest };
  