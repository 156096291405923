import * as React from "react";

import type { AsChildProp } from "@plan/core";
import { styled } from "@plan/core";

import { useField } from "./useField";

import { Slot } from "@radix-ui/react-slot";

const FORM_ERROR_MESSAGE_ELEMENT = "div";

const StyledFieldError = styled(FORM_ERROR_MESSAGE_ELEMENT, {
  textStyle: "medium",
  color: "$colors$danger",
  variants: {
    disabled: {
      false: {},
      true: { color: "$colors$-danger20" },
    },
    hidden: {
      false: {},
      true: { display: "none" },
    },
  },
  defaultVariants: {
    disabled: false,
    hidden: false,
  },
});

export interface FieldErrorProps
  extends AsChildProp,
    Omit<React.ComponentProps<typeof StyledFieldError>, "as" | "hidden"> {}

export const FieldError = React.forwardRef<HTMLDivElement, FieldErrorProps>(
  ({ asChild, children, ...props }, ref) => {
    const Comp = asChild ? Slot : FORM_ERROR_MESSAGE_ELEMENT;

    const { getFieldErrorProps, setHasFieldError } = useField();
    const formErrorMessageProps = getFieldErrorProps(props);

    React.useEffect(() => {
      setHasFieldError(true);

      return () => {
        setHasFieldError(false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !React.Children.count(children) ? null : (
      <StyledFieldError ref={ref} as={Comp} {...formErrorMessageProps}>
        {children}
      </StyledFieldError>
    );
  }
);
